/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.fsf.org/resources/formats/playogg/en"
  }, React.createElement(_components.img, {
    src: "images/play_ogg_large.png",
    alt: ""
  }))), "\n", React.createElement(_components.p, null, "Hoy va de banners la cosa, así me comprometo con buenas causas y ademas pongo un poco de color que está muy soso últimamente el blog."), "\n", React.createElement(_components.p, null, "Ogg es un conjunto de formatos multimedia, básicamente música y audio, su peculiaridad principal es que son formatos abiertos, es decir que no tenemos que pagar a nadie por su uso y además no son propiedad de nadie y podemos usarlos para lo que queramos. Las calidades de estos formatos no tienen nada que envidiar a los mas conocidos como mp3, por eso son una opción perfectamente válida siempre y cuando nuestros reproductores acepten estos formatos, cosa que por desgracia no siempre puede ser."), "\n", React.createElement(_components.p, null, "Saludos"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
